@import '@/styles/variables.scss';



























































































body .v-expansion-panel {
  position: relative;
}
.v-application.theme--light .v-expansion-panel.v-expansion-panel--custom.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-expansion-panel.v-expansion-panel--custom::before {
  box-shadow: none !important;
}
.v-switch--custom {
  position: absolute;
  right: 40px;
  top: -7px;
  z-index: 10;
}
