@import '@/styles/variables.scss';










































































































































































































































































































































































.two-factor-auth {
  max-width: 25rem;
  &__field {
    flex: 0 0 auto;
  }
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
